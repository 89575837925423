.account-activated-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.account-activated-container button {
    margin-top: 20px;
    padding: 10px 20px;
}