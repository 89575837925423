.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  
  .login-form input,
  .login-form button {
    margin-bottom: 10px;
    padding: 10px;
  }

  .forgot-password-link {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: blue;
    text-decoration: none;
  }

  .error {
    color: red;
    margin-bottom: 10px;
  }