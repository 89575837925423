.user-admin-page {
    display: flex;
    justify-content: space-between;
  }
  
  .user-profile-container {
    flex: 1;
    padding: 20px;
  }
  
  .user-admin-container {
    flex: 2;
    padding: 20px;
  }